import React, { useState, useEffect } from 'react';

import DatePicker from "react-datepicker";
import Select from 'react-select';
import { Modal, Button, Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";

import { PlanType, GradedWeekType, CoachingType } from '../../shared/Primitives';
import { WeekPlanInfo } from '../../api/types';

import { coachingTypeValues } from '../../fixtures/coaching-types';
import { weekTypeValuesByCoachingType } from '../../fixtures/week-types';

interface Props {
    weekPlan: WeekPlanInfo,
    onClose: () => void,
    onSave: (weekPlan: WeekPlanInfo) => void,
    onDelete: (weekPlanId: number) => void,
    onGenerate: (weekPlanId: number) => void,
}

const ModifyWeekPlanModal = (props: Props) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [startAt, setStartAt] = useState<Date>(new Date());
    const [planType, setPlanType] = useState<PlanType>(PlanType.km5);
    const [gradedWeekType, setGradedWeekType] = useState<GradedWeekType>(GradedWeekType.Grade1);

    const [coachingType, setCoachingType] = useState<CoachingType>(CoachingType.RunningFitness);
    const [weekType, setWeekType] = useState<string>('');
    const [weekTypeValues, setWeekTypeValues] = useState<any[]>([]);

    useEffect(() => {
        if (typeof props.weekPlan !== 'undefined' && props.weekPlan) {
            const wp = props.weekPlan;
            if (wp.id > 0) {
                setIsEditMode(true);
            }

            setCoachingType(wp.coachingType);
            setWeekType(wp.weekType);
            setStartAt(wp.startAt);
            setPlanType(wp.planType);
            updateWeekTypeValues(wp.coachingType, wp.weekType);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // second argument prevent to run on EVERY render.

    const updateWeekTypeValues = (coachingType: CoachingType, selectedWeekType: string) => {
        var newWeekTypes = weekTypeValuesByCoachingType.find(x => x.coachingType === coachingType)?.weekTypes ?? [];
        setWeekTypeValues(newWeekTypes);
        const newWeekType = newWeekTypes.find(x => x.value === selectedWeekType)?.value ?? newWeekTypes[0]?.value;
        setWeekType(newWeekType);
    }

    const onSave = () => {
        const obj = {
            id: props.weekPlan.id,
            startAt: startAt,
            planType: planType,
            weekType: weekType,
            weekSubType: gradedWeekType,
            title: props.weekPlan.title,
            coachingType: coachingType,
            distancePlanned: 0,
            distanceCompleted: 0,
            durationPlanned: 0,
            durationCompleted: 0,
        };

        props.onSave(obj);
    }

    const onDelete = () => {
        props.onDelete(props.weekPlan.id);
    }

    const onGenerate = () => {
        props.onGenerate(props.weekPlan.id);
    }

    const onDateChanged = (selectedDate: Date) => {
        setStartAt(selectedDate);
    }

    const onCoachingTypeChanged = (x: any, actionMeta: any) => {
        setCoachingType(x.value);
        updateWeekTypeValues(x.value as CoachingType, weekType);
    }

    const onWeekTypeChanged = (x: any, actionMeta: any) => {
        setWeekType(x.value);
    }

    const isMonday = (date: Date) => {
        const day = date.getDay();
        return day === 1;
    }

    return <div>
        <Modal show={true} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isEditMode ? "Edit week plan" : "New week plan"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="coachingType">
                    <Form.Label>Coaching Type</Form.Label>
                    <Select
                        value={coachingTypeValues.find(x => x.value === coachingType)}
                        options={coachingTypeValues}
                        getOptionLabel={x => x.name}
                        getOptionValue={x => `${x.value}`}
                        onChange={onCoachingTypeChanged}
                        isDisabled={isEditMode}
                    />
                </Form.Group>
                <Form.Group controlId="startAt">
                    <Form.Label>Start At</Form.Label>
                    <div>
                        <DatePicker
                            className="form-control"
                            selected={startAt}
                            onChange={onDateChanged}
                            dateFormat="yyyy-MM-dd"
                            filterDate={isMonday}
                            locale="en-GB"
                        />
                    </div>
                </Form.Group>

                <React.Fragment>
                    <Form.Group controlId="weekType">
                        <Form.Label>Week Type</Form.Label>
                        <Select
                            value={weekTypeValues.find(x => x.value === weekType)}
                            options={weekTypeValues}
                            getOptionLabel={x => x.label}
                            getOptionValue={x => x.value}
                            onChange={onWeekTypeChanged}
                        />
                    </Form.Group>
                </React.Fragment>

            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex flex-row flex-grow-1">
                    <div className="d-flex flex-grow-1 justify-content-start">
                        {props.weekPlan.id > 0 ? <Button variant="danger" onClick={onDelete}>Delete</Button> : null}
                    </div>
                    <div className="d-flex flex-grow-1 justify-content-end">
                        {props.weekPlan.id > 0 ? <Button variant="warning" onClick={onGenerate} className="mx-2">Generate</Button> : null}
                        <Button variant="primary" onClick={onSave}>Save</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    </div>
}

export default ModifyWeekPlanModal;
