import React, { useState, useEffect } from 'react';
import './AutoMessages.scss';
import { BaseCampClient } from '../../api/BaseCampClient';
import { useAuth0 } from '../../react-auth0-spa';
import { RouteComponentProps } from 'react-router-dom';
import { AutoMessage, SaveAutoMessageRequest } from '../../api/types';
import { Col, Form, Row } from 'react-bootstrap';
import TimeSelector from './automessage-components/TimeSelector';
import { getSendTimeText } from '../../api/Helpers';
import MessageTextEditor from './automessage-components/MessageTextEditor';
import { AutoMessageChannel, AutoMessageEvent, AutoMessageSender, CoachingType, SendTimeType, SportType } from '../../shared/Primitives';
import { sportTypeValues } from '../../fixtures/sport-types';
import SenderSelector from './automessage-components/SenderSelector';
import useForm from 'react-hook-form';
import BotActionSelector from './automessage-components/BotActionSelector';
import { autoMessageEventTypeValues } from '../../fixtures/auto-message-event-types';
import { coachingTypeValues } from '../../fixtures/coaching-types';

interface Props extends RouteComponentProps<any> { }

const AutoMessageDetailsView = (props: Props) => {
    const { accessToken } = useAuth0();
    const baseCamp: BaseCampClient = new BaseCampClient(accessToken);

    const messageId = props.match.params.messageId;

    const [message, setMessage] = useState<AutoMessage>({
        eventType: AutoMessageEvent.CreateUser,
        id: 0,
        createdAt: new Date(),
        messageDe: "",
        messageEn: "",
        messageRu: "",
        sendTimeDays: 0,
        sendTimeHours: 0,
        sendTimeMinutes: 0,
        sendTimeSeconds: 0,
        sendTimeType: SendTimeType.Delay,
        senderId: undefined,
        senderEmail: undefined,
        senderName: undefined,
        senderType: AutoMessageSender.Admin,
        sportTypes: [],
        coachingTypes: [],
        title: undefined,
        action: undefined,
        actionCaptionDe: undefined,
        actionCaptionEn: undefined,
        actionCaptionRu: undefined,
        actionType: undefined,
        sendWithoutConversationOnly: false,
        sendWithNoPaymentOnly: false,
        isActive: true,
        channels: []
    } as AutoMessage);
    const [error, setError] = useState<string | undefined>(undefined);

    //const events = [AutoMessageEvent.CreateUser, AutoMessageEvent.FirstBotRunCommand, AutoMessageEvent.FirstBotBikeCommand];
    const channels = [AutoMessageChannel.Chat, AutoMessageChannel.Telegram]

    const { handleSubmit } = useForm();

    useEffect(() => {
        setError(undefined);
        if (messageId > 0) {
            baseCamp.getAutoMessage(messageId)
                .then((res) => {
                    setMessage(res);
                })
                .catch((ex) => {
                    console.log("loading auto message error:", ex);
                    let msg = "There was an error during load data. Please try latter.";
                    if (ex.response?.status === 400) {
                        msg = ex.response.data;
                    }
                    setError(msg);
                })
        }
    }, []);

    const handleSportTypeChanged = (isChecked: boolean, sportType: SportType) => {
        const currentSportTypes = message.sportTypes ?? [];
        if (isChecked && !currentSportTypes.includes(sportType)) {
            currentSportTypes.push(sportType);
        }
        if (!isChecked) {
            const index = currentSportTypes.indexOf(sportType);
            if (index >= 0) {
                currentSportTypes.splice(index, 1);
            }
        }
        setMessage({ ...message, sportTypes: currentSportTypes });
    }

    const handleCoachingTypeChanged = (isChecked: boolean, coachingType: CoachingType) => {
        const ctValue = parseInt(coachingType.toString());
        const currentCoachingTypes = message.coachingTypes ?? [];

        if (isChecked && !currentCoachingTypes.includes(ctValue)) {
            currentCoachingTypes.push(ctValue);
        }

        if (!isChecked) {
            const index = currentCoachingTypes.indexOf(ctValue);
            if (index >= 0) {
                currentCoachingTypes.splice(index, 1);
            }
        }

        setMessage({ ...message, coachingTypes: currentCoachingTypes });
    }

    const handleChannelsChanged = (isChecked: boolean, channel: AutoMessageChannel) => {
        const currentChannels = message.channels ?? [];
        if (isChecked && !currentChannels.includes(channel)) {
            currentChannels.push(channel);
        }
        if (!isChecked) {
            const index = currentChannels.indexOf(channel);
            if (index >= 0) {
                currentChannels.splice(index, 1);
            }
        }
        setMessage({ ...message, channels: currentChannels });
    }

    const handleSendWithoutConversationOnly = (isChecked: boolean) => {
        setMessage({ ...message, sendWithoutConversationOnly: isChecked });
    }

    const handleSendWithNoPaymentOnly = (isChecked: boolean) => {
        setMessage({ ...message, sendWithNoPaymentOnly: isChecked });
    }

    const handleIsActive = (isChecked: boolean) => {
        setMessage({ ...message, isActive: isChecked });
    }

    const validate = (): boolean => {
        let validateList: string[] = [];
        if (message.messageRu.length === 0) {
            validateList.push("Message Text Ru must be specified.");
        }
        if (message.messageEn.length === 0) {
            validateList.push("Message Text En must be specified.");
        }
        if (message.sportTypes.length === 0) {
            validateList.push("Sport types must be specified.");
        }
        if (message.coachingTypes.length === 0) {
            validateList.push("Coaching types must be specified.");
        }
        if (message.channels.length===0) {
            validateList.push("Channels must be specified.");
        }
        if (message.senderType === AutoMessageSender.Bot && message.channels.includes(AutoMessageChannel.Chat)) {
            validateList.push("Channel can't be Chat for Bot sender type.");
        }

        setError(validateList.join(" "));

        return validateList.length === 0;
    }

    const saveData = () => {
        if (!validate()) {
            return;
        }

        const coachingTypeValues = message.coachingTypes.map((x) => parseInt(x.toString()));
        const distinctCoachingTypes: number[] = Array.from(new Set(coachingTypeValues));

        let req: SaveAutoMessageRequest = {
            eventType: message.eventType,
            id: messageId,
            messageDe: message.messageDe,
            messageEn: message.messageEn,
            messageRu: message.messageRu,
            sendTimeDays: message.sendTimeDays,
            sendTimeHours: message.sendTimeHours,
            sendTimeMinutes: message.sendTimeMinutes,
            sendTimeSeconds: message.sendTimeSeconds,
            sendTimeType: message.sendTimeType,
            senderId: message.senderId,
            senderType: message.senderType,
            sportTypes: message.sportTypes,
            coachingTypes: distinctCoachingTypes,
            title: message.title,
            action: message.action,
            actionCaptionDe: message.actionCaptionDe,
            actionCaptionEn: message.actionCaptionEn,
            actionCaptionRu: message.actionCaptionRu,
            actionType: message.actionType,
            sendWithoutConversationOnly: message.sendWithoutConversationOnly,
            sendWithNoPaymentOnly: message.sendWithNoPaymentOnly,
            isActive: message.isActive,
            channels: message.channels
        };

        baseCamp.saveAutoMessage(req)
            .then((res) => {
                setMessage(res);
                alert("Auto message was successfully saved!");
                window.location.href = `/auto-messages`;
            })
            .catch((ex) => {
                console.log("Save auto message error:", ex);
                let msg = "There was an error during saving auto message. Please try latter.";
                if (ex.response?.status === 400) {
                    msg = ex.response.data;
                }
                setError(msg);
            })
    }

    return <div className="auto-messages auto-message-details">
        {
            error ? <div className="fade alert alert-danger show">{error}</div> : null
        }
        <h3>
            {
                messageId > 0 ? `Edit Auto Message: ${messageId}` : "New Auto Message"
            }
        </h3>
        <Form>
            <Form.Group>
                <Row>
                    <Form.Label column sm="2">Active:</Form.Label>
                    <Col sm={10}>
                        <Form.Check type="checkbox" custom
                            id="cbIsActive"
                            label=""
                            checked={message?.isActive ?? false}
                            onChange={(e: any) => { handleIsActive(e.target.checked) }}
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group controlId="messageTitle">
                <Form.Label>Title:</Form.Label>
                <Form.Control type="text" placeholder="Title" value={message?.title || ""}
                    onChange={(e) => {
                        setMessage({ ...message, title: e.currentTarget.value });
                    }}
                    isValid={false}
                />
            </Form.Group>
            <Form.Group controlId="messageSendTime">
                <Form.Label>Send time: <b>{getSendTimeText(message)}</b></Form.Label>
                <TimeSelector
                    message={message}
                    sendTimeTypeChanged={(value) => { setMessage({ ...message, sendTimeType: value }); }}
                    sendTimeDaysChanged={(value) => { setMessage({ ...message, sendTimeDays: value }); }}
                    sendTimeHoursChanged={(value) => { setMessage({ ...message, sendTimeHours: value }); }}
                    sendTimeMinutesChanged={(value) => { setMessage({ ...message, sendTimeMinutes: value }); }}
                    sendTimeSecondsChanged={(value) => { setMessage({ ...message, sendTimeSeconds: value }); }}
                />
            </Form.Group>
            <Form.Group controlId="messageTextRu">
                <Form.Label>Message Text Ru:</Form.Label>
                <MessageTextEditor
                    messageText={message.messageRu}
                    messageTextChanged={(value) => { setMessage({ ...message, messageRu: value }); }}
                />
            </Form.Group>
            <Form.Group controlId="messageTextEn">
                <Form.Label>Message Text En:</Form.Label>
                <MessageTextEditor
                    messageText={message.messageEn}
                    messageTextChanged={(value) => { setMessage({ ...message, messageEn: value }); }}
                />
            </Form.Group>
            <Form.Group controlId="messageTextDe">
                <Form.Label>Message Text De:</Form.Label>
                <MessageTextEditor
                    messageText={message.messageDe}
                    messageTextChanged={(value) => { setMessage({ ...message, messageDe: value }); }}
                />
            </Form.Group>
            <Form.Group controlId="messageEvent">
                <Row>
                    <Form.Label column sm="2">Event:</Form.Label>
                    <Col sm={2}>
                        <Form.Control as="select" value={message.eventType} onChange={(e) => { setMessage({ ...message, eventType: e.target.value as AutoMessageEvent }); }}>
                            {
                                autoMessageEventTypeValues.map((x) => {
                                    return <option key={x.value} value={x.value}>{x.label}</option>
                                })
                            }
                        </Form.Control>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Form.Label column sm="2">Sport types:</Form.Label>
                    <Col sm={10}>
                        {
                            sportTypeValues.map((x) => {
                                return <Form.Check key={x.value} custom inline type="checkbox" id={`message-sport-${x.value}`}
                                    label={x.label}
                                    value={x.value}
                                    checked={message?.sportTypes?.includes(x.value) ?? false}
                                    onChange={(e: any) => { handleSportTypeChanged(e.target.checked, e.target.value) }}
                                />
                            })
                        }
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Form.Label column sm="2">Coaching types:</Form.Label>
                    <Col sm={10}>
                        {
                            coachingTypeValues.map((x) => {
                                const checked = message?.coachingTypes?.includes(x.value) ?? false;

                                return <Form.Check key={x.value} custom inline type="checkbox" id={`message-coaching-${x.name}`}
                                    label={x.name}
                                    value={x.value}
                                    checked={checked}
                                    onChange={(e: any) => { handleCoachingTypeChanged(e.target.checked, e.target.value) }}
                                />
                            })
                        }
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Form.Label column sm="2">Send without conversation:</Form.Label>
                    <Col sm={10}>
                        <Form.Check type="checkbox" custom
                            id="cbSendWithoutConversationOnly"
                            label=""
                            checked={message?.sendWithoutConversationOnly ?? false}
                            onChange={(e: any) => { handleSendWithoutConversationOnly(e.target.checked) }}
                        />
                        <Form.Text id="passwordHelpBlock" muted>
                            Message will be sent if user doesn't have any conversation
                        </Form.Text>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Form.Label column sm="2">Send with no payment:</Form.Label>
                    <Col sm={10}>
                        <Form.Check type="checkbox" custom
                            id="cbSendWithNoPaymentOnly"
                            label=""
                            checked={message?.sendWithNoPaymentOnly ?? false}
                            onChange={(e: any) => { handleSendWithNoPaymentOnly(e.target.checked) }}
                        />
                        <Form.Text id="passwordHelpBlock" muted>
                            Message will be sent if user has trial period or doesn't have active plan
                        </Form.Text>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Form.Label column sm="2">Sender:</Form.Label>
                    <SenderSelector
                        senderType={message.senderType}
                        senderId={message.senderId}
                        senderTypeChanged={(e) => {
                            if (e === AutoMessageSender.Bot) {
                                const newChannels = message.channels ?? [];
                                const index = newChannels.indexOf(AutoMessageChannel.Chat);
                                if (index >= 0) {
                                    newChannels.splice(index, 1);
                                    setMessage({ ...message, channels: newChannels });
                                }
                            }
                            setMessage({ ...message, senderType: e });
                        }}
                        senderIdChanged={(e) => { setMessage({ ...message, senderId: e }) }}
                    />
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Form.Label column sm="2">Channels:</Form.Label>
                    <Col sm={10}>
                        {
                            channels.map((x) => {
                                return <Form.Check key={x} custom inline type="checkbox" id={`message-channel-${x}`}
                                    label={x}
                                    value={x}
                                    disabled={message?.senderType === AutoMessageSender.Bot && x === AutoMessageChannel.Chat}
                                    checked={message?.channels?.includes(x) ?? false}
                                    onChange={(e: any) => { handleChannelsChanged(e.target.checked, e.target.value) }}
                                />
                            })
                        }
                        <Form.Text id="channelHelpBlock" muted>
                            The channels to send message. Chat - message will be sent to chat. Telegram - message will be sent to telegram bot.
                        </Form.Text>
                    </Col>
                </Row>
            </Form.Group>
            <BotActionSelector
                show={message.senderType === AutoMessageSender.Bot}
                action={message.action}
                actionCaptionEn={message.actionCaptionEn}
                actionCaptionRu={message.actionCaptionRu}
                actionType={message.actionType}
                actionTypeChanged={(value) => { setMessage({ ...message, actionType: value }) }}
                actionChanged={(value) => { setMessage({ ...message, action: value }) }}
                actionCaptionRuChanged={(value) => { setMessage({ ...message, actionCaptionRu: value }) }}
                actionCaptionEnChanged={(value) => { setMessage({ ...message, actionCaptionEn: value }) }}
            />

            <Row className="bottom-button-panel">
                <button type="submit" className="btn btn-sm btn-success" onClick={handleSubmit(saveData)}>Save</button>
                <button className="btn btn-sm btn-primary" onClick={() => { props.history.push("/auto-messages") }}>Cancel</button>
            </Row>
        </Form>
    </div>
}

export default AutoMessageDetailsView;