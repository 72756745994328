import React, { useState, useEffect, createRef } from 'react';

import { useHistory, Link } from "react-router-dom";
import { useAuth0 } from "./../../react-auth0-spa";
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import * as Icon from 'react-feather';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import { UserFullInfo, UserInfoBaseFilter, Dictionary, CoachInfo, UserInfoFilter } from '../../api/types';
import { BaseCampClient } from '../../api/BaseCampClient';
import { parseDate } from '../../api/Helpers';

import './UsersView.scss';
import Loader from "../Loader";
import { Form } from 'react-bootstrap';
import { SportType, UserType, SubscriptionPeriods, OperationSystemType } from '../../shared/Primitives';
import { userTypes } from '../../fixtures/user-types';
import { hasRoles } from "../../api/Helpers";
import { ChatDirectorClient } from '../../api/ChatDirectorClient';

const UsersView = () => {
    const history = useHistory();

    const handleAddClick = () => {
        history.push("/users/add");
    }

    const { accessToken } = useAuth0();

    const [users, setUsers] = useState([] as UserFullInfo[]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [totalRows, setTotalRows] = useState(0);
    const [sortField, setSortField] = useState("id");
    const [sortDirection, setSortDirection] = useState(0);
    const [sortCustom, setSortCustom] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [useFilter, setUseFilter] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [filterString, setFilterString] = useState("");
    const [filterRequest, setFilterRequest] = useState({
        dateFrom: new Date(),
        dateTo: new Date(),
        dateFields: [],
        stringFields: [],
        numberFields: [],
        numberFrom: undefined,
        numberTo: undefined,
        activeOnly: false,
        stringSearch: undefined,
        stravaConnected: undefined,
        hasConsent: undefined,
        userTypes: undefined,
        languages: undefined,
        subscriptions: [],
        coachId: undefined,
        subscriptionPeriods: [],
        operationSystems: []
    } as UserInfoBaseFilter);
    const [selectedUserIds, setSelectedUserIds] = useState({} as Record<number, number>);
    const [showSendMessages, setShowSendMessages] = useState(false);
    const [message, setMessage] = useState("");
    const [isSelectCheckboxUpdate, setSelectCheckboxUpdate] = useState(false);
    const [allUsersSelected, setAllUsersSelected] = useState(undefined as boolean | undefined);
    const [coaches, setCoaches] = useState({} as CoachInfo[]);
    const [showSubscriptionPeriods, setShowSubscriptionPeriods] = useState<boolean>(false);

    let usersListRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (!isSelectCheckboxUpdate) {
            const basecamp: BaseCampClient = new BaseCampClient(accessToken);
            loadUsers(basecamp);
        }
        setSelectCheckboxUpdate(false);
    }, [accessToken, page, perPage, sortField, sortDirection, refresh, filterString, selectedUserIds, allUsersSelected]);

    const loadUsers = (basecamp: BaseCampClient) => {
        setLoading(true);
        var request = getFilterRequest();
        basecamp.getUsersList(request).then(res => {
            setSelectCheckboxUpdate(true); //to not refresh always
            const ids = res.selectedUserIds ? res.selectedUserIds.reduce((acc, item) => (acc[item] = item, acc), {} as Record<number, number>) : {};
            setSelectedUserIds(ids);

            setUsers(res.users);
            setTotalRows(res.totalCount);
        })
            .finally(() => setLoading(false));
    };

    const exportUsers = async () => {
        const basecamp: BaseCampClient = new BaseCampClient(accessToken);
        setLoading(true);
        var request = getFilterRequest();
        basecamp.getUsersCsv(request)
            .finally(() => setLoading(false));
    }

    const exportPayments = async () => {
        const basecamp: BaseCampClient = new BaseCampClient(accessToken);
        setLoading(true);
        basecamp.getPaymentsCsv()
            .finally(() => setLoading(false));
    }

    const getFilterRequest = (): UserInfoFilter => {
        return {
            page: page - 1,
            perPage,
            sortField,
            sortDirection,
            isCustomSort: sortCustom,
            useFilter,
            filterString,
            ...{
                ...filterRequest,
                dateFrom: parseDate(filterRequest.dateFrom.toString()),
                dateTo: parseDate(filterRequest.dateTo.toString()),
            },
            selectedUserIds: selectedUserIds ? Object.values(selectedUserIds) : undefined,
            isAllUsersSelected: allUsersSelected
        };
    }

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (perPage: number, page: number) => {
        setPerPage(perPage);
        setPage(page);
    }

    const handleSort = (column: IDataTableColumn<UserFullInfo>, sortDirectionStr: string) => {
        setSortField(column.selector as string);
        setSortCustom(/telegramCommandsCount|telegramInitDate|telegramId|hasConnectedStrava|isActive|sportType|goalRace|activeRunningPlanStartedAt|coachName|subscriptionPlanTitle|operationSystem/.test(column.selector as string));
        setSortDirection(sortDirectionStr === 'asc' ? 0 : 1);
    };

    const renderCellGoalRace = (user: UserFullInfo) => {
        return !user.goalRace ? "" : <React.Fragment>
            {user.goalRace.distanceKm} km at<br />{user.goalRace.date.toString().slice(0, 10)}
        </React.Fragment>;
    };

    const renderHasConsentCheckbox = (user: UserFullInfo) => {
        return <React.Fragment>
            <input type="checkbox" checked={user.hasConsent} disabled></input>
        </React.Fragment>;
    };

    const renderHasConnectedStravaCheckbox = (user: UserFullInfo) => {
        return <React.Fragment>
            <input type="checkbox" checked={user.hasConnectedStrava} disabled></input>
        </React.Fragment>;
    };

    const renderIsActiveCell = (user: UserFullInfo) => {
        return !user.isActive ? "" : <React.Fragment>
            <Icon.Activity color="green" />
        </React.Fragment>;
    };

    const renderSportTypes = (user: UserFullInfo) => {
        return <React.Fragment>
            {user.isRunner ? <div>Runner</div> : ""}
            {user.isBiker ? <div>Biker</div> : ""}
        </React.Fragment>;
    };

    const renderSelectCheckbox = (user: UserFullInfo) => {
        return <input type="checkbox" value={user.id} checked={selectedUserIds ? user.id in selectedUserIds : false}
            onChange={(e) => {
                let selectedUserCount = Object.values(selectedUserIds).length;
                const id = Number(e.target.value);
                if (e.target.checked && !(id in selectedUserIds)) {
                    setSelectedUserIds({ ...selectedUserIds, [id]: id });
                    selectedUserCount++;
                }
                else if (id in selectedUserIds) {
                    delete selectedUserIds[id];
                    setSelectedUserIds({ ...selectedUserIds });
                    selectedUserCount--;
                }

                const isAll = selectedUserCount && selectedUserCount === totalRows ? true : undefined;
                setAllUsersSelected(isAll);
                setSelectCheckboxUpdate(true);
            }
            }
        >
        </input>;
    };

    const handleSendMessageClick = () => {
        setShowSendMessages(true);
    }

    const renderCellEmail = (user: UserFullInfo) => {
        return !user.email ? "" : <Link to={`/user/${user.id}/profile`}>{user.email}</Link>;
    }

    const handleFilterClick = () => {
        setShowFilter(true);
        const basecamp: BaseCampClient = new BaseCampClient(accessToken);
        basecamp.getProfileDefaultData()
            .then(data => {
                setCoaches(data.coaches);
            });
    }

    const handleApplyFilterClick = () => {
        setShowFilter(false);
        setUseFilter(true);
        setRefresh(!refresh);
    }

    const handleClearFilterClick = () => {
        setFilterRequest({
            dateFrom: new Date(),
            dateTo: new Date(),
            numberFrom: 0,
            numberTo: 0,
            stringSearch: "",
            dateFields: [],
            stringFields: [],
            numberFields: [],
            activeOnly: false,
            hasConsent: undefined,
            stravaConnected: undefined,
            userTypes: undefined,
            languages: undefined,
            coachId: undefined,
            subscriptions: [],
            subscriptionPeriods: [],
            operationSystems: []
        });
        setShowFilter(false);
        setUseFilter(false);
        setRefresh(!refresh);
    }

    const changeFilterFields = (checked: boolean, arr: string[], field: string) => {
        if (checked) {
            arr.push(field);
        }
        else {
            arr = arr.filter(a => a !== field);
        }
        return arr;
    }

    const createFilterCheck = (params: Dictionary<string>[], fields: string[], propName: string, labelSize: number = 3) => {
        return params.map(param =>
            <div className="form-group row" key={param.key}>
                <label className={`col-sm-${labelSize} col-form-label`}>{param.value}</label>
                <div className="col-sm-1">
                    <input type="checkbox" className="form-control"
                        defaultChecked={fields.some(a => a === param.key)}
                        onChange={(event) => {
                            let currentPeriods = filterRequest.subscriptionPeriods ?? [];
                            if (param.key === 'payment') {
                                if (event.target.checked) {
                                    setShowSubscriptionPeriods(true);
                                }
                                else {
                                    setShowSubscriptionPeriods(false);
                                    currentPeriods = [];
                                }
                            }
                            setFilterRequest({
                                ...filterRequest,
                                [propName]: changeFilterFields(event.target.checked, fields, param.key),
                                subscriptionPeriods: currentPeriods
                            })
                        }
                        } />
                </div>
            </div>
        );
    }

    let timer: NodeJS.Timeout | undefined;
    const searchFieldChange = (s: string) => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            if (s.length || filterString.length > 0) {
                setFilterString(s);
            }
        }, 400);
    }

    const baseColumnSetting: IDataTableColumn<UserFullInfo> = {
        name: "",
        center: true,
        sortable: true,
        compact: true,
        width: "60px"
    };

    const sportTypesColStyle = {
        'display': 'block',
        'text-align': 'center'
    };

    const columns: IDataTableColumn<UserFullInfo>[] = [
        {
            ...baseColumnSetting,
            name: <input type="checkbox" checked={allUsersSelected}
                onChange={(e) => {
                    setAllUsersSelected(e.target.checked);
                }
                } />,
            selector: "id",
            cell: renderSelectCheckbox,
            sortable: false,
        },
        {
            ...baseColumnSetting,
            name: 'Active',
            selector: 'isActive',
            cell: renderIsActiveCell
        },
        {
            ...baseColumnSetting,
            name: 'Id',
            selector: 'id'
        },
        {
            ...baseColumnSetting,
            name: 'Email',
            selector: 'email',
            width: "150px",
            center: false,
            cell: renderCellEmail
        },
        {
            ...baseColumnSetting,
            name: 'User Type',
            selector: 'userType',
            width: "80px",
            cell: row => userTypes.find(ut => ut.value === row.userType)?.name
        },
        {
            ...baseColumnSetting,
            name: 'First Name',
            selector: 'firstName',
            width: "120px"
        },
        {
            ...baseColumnSetting,
            name: 'Language',
            selector: 'language',
            width: "80px"
        },
        {
            ...baseColumnSetting,
            name: 'Coach',
            selector: 'coachName',
            width: "80px",
            omit: !hasRoles(accessToken, "admin"),
            cell: row => row.coach?.name
        },
        {
            ...baseColumnSetting,
            name: 'Running Plan Started At',
            selector: 'activeRunningPlanStartedAt',
            width: "90px",
            cell: row => row.activeRunningPlanStartedAt?.toString().slice(0, 10)
        },
        {
            ...baseColumnSetting,
            name: 'Goal Race',
            selector: 'goalRace',
            width: "80px",
            center: false,
            cell: renderCellGoalRace
        },
        {
            ...baseColumnSetting,
            name: 'Registered At',
            selector: 'createdAt',
            width: "100px",
            cell: row => row.createdAt?.toString().slice(0, 10)
        },
        {
            ...baseColumnSetting,
            name: 'OS',
            selector: 'operationSystem'
        },
        {
            ...baseColumnSetting,
            name: 'Subscription',
            selector: 'subscriptionPlanTitle',
            width: "120px"
        },
        {
            ...baseColumnSetting,
            name: 'Has Consent',
            selector: 'hasConsent',
            cell: renderHasConsentCheckbox
        },
        {
            ...baseColumnSetting,
            name: 'Strava',
            selector: 'hasConnectedStrava',
            cell: renderHasConnectedStravaCheckbox
        }
    ];

    const handleSendMessage = () => {
        if (message.trim().length === 0) {
            alert("Message can't be empty!");
            return;
        }

        const conf = window.confirm('Are you sure you want to send message to ' + Object.keys(selectedUserIds).length.toString() + ' user(s)?');
        if (conf) {
            setLoading(true);
            const chatDirectorClient = new ChatDirectorClient(accessToken);
            chatDirectorClient.broadcastMessage({
                userIds: Object.values(selectedUserIds),
                body: message
            })
                .then(_ => {
                    alert("Message has been sent.");
                    setMessage("");
                })
                .catch((ex) => {
                    alert(ex.message);
                })
                .finally(() => setLoading(false));
        }
    }

    const handleCancelSendMessage = () => {
        setShowSendMessages(false);
        setShowFilter(false);
    }

    const handleImportUser = () => {
        const basecamp: BaseCampClient = new BaseCampClient(accessToken);
        basecamp.optInUsers()
            .then(() => {
                alert("Users have been successfully queued to import into SendGrid.");
            })
            .catch((ex) => {
                alert(ex.message);
            })
    }
    const filterHandleStravaChanged = (event: any) => {
        setFilterRequest({ ...filterRequest, stravaConnected: event.target.checked });
    }

    const filterHandleConsentChanged = (event: any) => {
        setFilterRequest({ ...filterRequest, hasConsent: event.target.checked });
    }

    const handleUserTypeChanged = (isChecked: boolean, userType: UserType) => {
        const currentUserTypes = filterRequest.userTypes ?? [];
        if (isChecked && !filterRequest.userTypes?.includes(userType)) {
            currentUserTypes.push(userType);
        }
        if (!isChecked) {
            const index = currentUserTypes.indexOf(userType);
            if (index >= 0) {
                currentUserTypes.splice(index, 1);
            }
        }
        setFilterRequest({ ...filterRequest, userTypes: currentUserTypes });
    }

    const handleOperationSystemChanged = (isChecked: boolean, operationSystem: OperationSystemType) => {
        const currentOs = filterRequest.operationSystems ?? [];
        if (isChecked && !filterRequest.operationSystems?.includes(operationSystem)) {
            currentOs.push(operationSystem);
        }
        if (!isChecked) {
            const index = currentOs.indexOf(operationSystem);
            if (index >= 0) {
                currentOs.splice(index, 1);
            }
        }
        setFilterRequest({ ...filterRequest, operationSystems: currentOs });
    }

    const handleLanguageChanged = (isChecked: boolean, lang: string) => {
        const currentLanguages = filterRequest.languages ?? [];
        if (isChecked && !filterRequest.languages?.includes(lang)) {
            currentLanguages.push(lang);
        }
        if (!isChecked) {
            const index = currentLanguages.indexOf(lang);
            if (index >= 0) {
                currentLanguages.splice(index, 1);
            }
        }
        setFilterRequest({ ...filterRequest, languages: currentLanguages });
    }

    const handleSubscriptionsChanged = (isChecked: boolean, subscription: string) => {
        const currentSubscriptions = filterRequest.subscriptions ?? [];
        if (isChecked && !currentSubscriptions.includes(subscription)) {
            currentSubscriptions.push(subscription);
        }
        if (!isChecked) {
            const index = currentSubscriptions.indexOf(subscription);
            if (index >= 0) {
                currentSubscriptions.splice(index, 1);
            }
        }
        setFilterRequest({ ...filterRequest, subscriptions: currentSubscriptions });
    }

    const handleSubscriptionPeriodsChanged = (isChecked: boolean, period: SubscriptionPeriods) => {
        const currentPeriods = filterRequest.subscriptionPeriods ?? [];
        if (isChecked && !currentPeriods.includes(period)) {
            currentPeriods.push(period);
        }
        if (!isChecked) {
            const index = currentPeriods.indexOf(period);
            if (index >= 0) {
                currentPeriods.splice(index, 1);
            }
        }
        setFilterRequest({ ...filterRequest, subscriptionPeriods: currentPeriods });
    }


    if (showSendMessages) {
        return (
            <div className="users-message-send">
                <h2>Send a chat message to {Object.keys(selectedUserIds).length} user(s)</h2>
                <div className="wrapper">
                    <div className="form-group clearfix">
                        <textarea
                            className="form-control"
                            id="outgoing-message"
                            rows={10}
                            value={message}
                            onChange={e => {
                                setMessage(e.target.value);
                            }}
                        >
                        </textarea>

                        <button onClick={handleCancelSendMessage} className="btn btn-secondary btn-sm my-2 float-right btn-margin-left">Back</button>
                        <button onClick={handleSendMessage} className="btn btn-danger btn-sm my-2 float-right">Send</button>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (<div className="users">
            {
                !showFilter ?
                    <React.Fragment>
                        <div className="users__actions">
                            {/*
                                hasRoles(accessToken, "admin")
                                    ? <button type="button" className="btn btn-sm btn-primary" onClick={handleAddClick}>Register a user for Telegram Bot</button>
                                    : null
                            */}
                            <button type="button" className="btn btn-sm btn-secondary" onClick={handleFilterClick}>
                                <Icon.Filter />Filter
                            </button>
                            {
                                hasRoles(accessToken, "admin") ?
                                    <button type="button" className="btn btn-sm btn-primary" onClick={handleSendMessageClick} disabled={!selectedUserIds || Object.keys(selectedUserIds).length === 0}>
                                        Send message to {selectedUserIds ? Object.keys(selectedUserIds).length : 0} user(s)
                                    </button>
                                    : null
                            }
                            <button type="button" className="btn btn-sm btn-primary" onClick={exportUsers}>Export Users</button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={exportPayments}>Export Payments</button>
                            <div className="float-right w-50">
                                <input type="text" className="form-control form-control-sm" placeholder="Search by ID, email, telegram id, first name..."
                                    onChange={(event) => searchFieldChange(event.target.value.trim())} />
                            </div>
                            <div style={{ clear: "both" }} />
                        </div>
                        <div className="users__list" ref={usersListRef}>
                            <DataTable columns={columns} data={users} noHeader className="users__table"
                                pagination paginationServer paginationTotalRows={totalRows}
                                paginationPerPage={perPage}
                                paginationRowsPerPageOptions={[10, 30, 50]}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                progressPending={loading} progressComponent={<Loader />}
                                sortServer defaultSortField={sortField} onSort={handleSort} />
                        </div>
                        {
                            hasRoles(accessToken, "admin") ?
                                <div>
                                    <button onClick={handleImportUser} type="button" className="btn btn-sm btn-primary">Import users to SendGrid</button>
                                </div>
                                : null
                        }
                    </React.Fragment>
                    :
                    <div className="users__filter">
                        <div>
                            <button type="button" className="btn btn-primary" onClick={handleApplyFilterClick}>Apply</button>
                            <button type="button" className="btn btn-secondary" onClick={handleClearFilterClick}>Clear</button>
                        </div>
                        <div className="w-50 float-left">
                            <h4>Filter by string values</h4>
                            <div className="form-group row">
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="Search value..."
                                        defaultValue={filterRequest.stringSearch}
                                        onChange={(event) => setFilterRequest({ ...filterRequest, stringSearch: event.target.value.trim() })} />
                                </div>
                            </div>
                            {
                                createFilterCheck([
                                    { key: "Email", value: "Email" },
                                    { key: "City", value: "City" }
                                ] as Dictionary<string>[], filterRequest.stringFields, "stringFields")
                            }
                            <h4>Filter by date values</h4>
                            <div className="form-group row">
                                <span className="col-sm-3 col-form-label">Date From</span>
                                <div className="col-sm-9">
                                    <DatePicker
                                        className="form-control"
                                        selected={filterRequest.dateFrom}
                                        onChange={date => {
                                            if (date) {
                                                setFilterRequest({ ...filterRequest, dateFrom: date, dateTo: date > filterRequest.dateTo ? date : filterRequest.dateTo });

                                            }
                                        }}
                                        dateFormat="yyyy-MM-dd" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <span className="col-sm-3 col-form-label">Date To</span>
                                <div className="col-sm-9">
                                    <DatePicker
                                        className="form-control"
                                        minDate={filterRequest.dateFrom}
                                        selected={filterRequest.dateTo}
                                        onChange={date => {
                                            if (date) {
                                                setFilterRequest({ ...filterRequest, dateTo: date })
                                            }
                                        }}
                                        dateFormat="yyyy-MM-dd" />
                                </div>
                            </div>
                            {
                                createFilterCheck([
                                    { key: "registeredDate", value: "Registered At" },
                                    { key: "goalRace", value: "Goal Race" },
                                    { key: "payment", value: "Payment" },
                                ] as Dictionary<string>[], filterRequest.dateFields, "dateFields")
                            }
                            {
                                showSubscriptionPeriods ?
                                    <div className="form-group row" key="paymentPeriod">
                                        <label className={`col-sm-3 col-form-label`}>Payment periods</label>
                                        <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                            <Form.Check type="checkbox" label="Monthly" custom inline id="cbMonthly"
                                                checked={filterRequest.subscriptionPeriods?.includes(SubscriptionPeriods.Monthly)}
                                                onChange={(e: any) => {
                                                    handleSubscriptionPeriodsChanged(e.target.checked, SubscriptionPeriods.Monthly);
                                                }
                                                }
                                            />
                                        </div>
                                        <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                            <Form.Check type="checkbox" label="Quarterly" custom inline id="cbQuarterly"
                                                checked={filterRequest.subscriptionPeriods?.includes(SubscriptionPeriods.Quarterly)}
                                                onChange={(e: any) => {
                                                    handleSubscriptionPeriodsChanged(e.target.checked, SubscriptionPeriods.Quarterly);
                                                }
                                                }
                                            />
                                        </div>
                                        <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                            <Form.Check type="checkbox" label="SemiAnnually" custom inline id="cbSemiAnnually"
                                                checked={filterRequest.subscriptionPeriods?.includes(SubscriptionPeriods.SemiAnnually)}
                                                onChange={(e: any) => {
                                                    handleSubscriptionPeriodsChanged(e.target.checked, SubscriptionPeriods.SemiAnnually);
                                                }
                                                }
                                            />
                                        </div>
                                        <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                            <Form.Check type="checkbox" label="Annually" custom inline id="cbAnnually"
                                                checked={filterRequest.subscriptionPeriods?.includes(SubscriptionPeriods.Annually)}
                                                onChange={(e: any) => {
                                                    handleSubscriptionPeriodsChanged(e.target.checked, SubscriptionPeriods.Annually);
                                                }
                                                }
                                            />
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                hasRoles(accessToken, "admin") ?
                                    <>
                                        <h4>Filter by coach</h4>
                                        <div className="form-group row">
                                            <label htmlFor="inputCoach" className="col-sm-3 col-form-label">Coach</label>
                                            {console.log("coaches", coaches)}
                                            <div className="col-sm-4">
                                                <Select id="inputCoach"
                                                    options={coaches}
                                                    isClearable
                                                    value={coaches.length > 0 ? coaches.find(b => b.coachId === filterRequest.coachId) : null}
                                                    getOptionLabel={b => `${b.nameEn ?? b.nameRu ?? b.nameDe}`}
                                                    getOptionValue={b => `${b.coachId}`}
                                                    onChange={value => setFilterRequest({ ...filterRequest, coachId: (value as CoachInfo)?.coachId })}

                                                />
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                        <div className="w-50 float-left">
                            <h4>Filter by number values</h4>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Between</label>
                                <div className="col-sm-5">
                                    <input type="number" className="form-control" defaultValue={!filterRequest.numberFrom && filterRequest.numberFrom !== 0 ? undefined : filterRequest.numberFrom}
                                        onChange={(event) => {
                                            let value: number | undefined = parseInt(event.target.value);
                                            value = value < 0 ? 0 : isNaN(value) ? undefined : value;
                                            setFilterRequest({ ...filterRequest, numberFrom: value });
                                        }}
                                        placeholder="Start..." />
                                </div>
                                <div className="col-sm-5">
                                    <input type="number" className="form-control" defaultValue={!filterRequest.numberTo && filterRequest.numberTo !== 0 ? undefined : filterRequest.numberTo}
                                        onChange={(event) => {
                                            let value: number | undefined = parseInt(event.target.value);
                                            value = value < 0 ? 0 : isNaN(value) ? undefined : value;
                                            setFilterRequest({ ...filterRequest, numberTo: value })
                                        }}
                                        placeholder="End..." />
                                </div>
                            </div>
                            {
                                createFilterCheck([
                                    { key: "Id", value: "ID" },
                                    { key: "Vdot", value: "Vdot" },
                                    { key: "Age", value: "Age" }
                                ] as Dictionary<string>[], filterRequest.numberFields, "numberFields", 2)
                            }

                            <h4>Other filters</h4>
                            <div className="form-group row" key="ActiveOnly">
                                <label className={`col-sm-2 col-form-label`}>Active only</label>
                                <div className="col-sm-1">
                                    <input type="checkbox" className="form-control"
                                        defaultChecked={filterRequest.activeOnly}
                                        onChange={(event) => {
                                            setFilterRequest({ ...filterRequest, activeOnly: event.target.checked })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-group row" key="StravaConnected">
                                <label className={`col-sm-2 col-form-label`}>Strava filter</label>
                                <div className="col-sm-1">
                                    <input type="checkbox" className="form-control"
                                        defaultChecked={filterRequest.stravaConnected !== undefined}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setFilterRequest({ ...filterRequest, stravaConnected: true })
                                            } else {
                                                setFilterRequest({ ...filterRequest, stravaConnected: undefined })
                                            }
                                        }}
                                    />
                                </div>
                                {
                                    filterRequest.stravaConnected !== undefined
                                        ?
                                        <div className="col-sm-3" style={{ "padding": ".375rem .75rem" }}>
                                            <Form.Check type="switch" id="stravaConnectedFlag"
                                                label={filterRequest.stravaConnected ? "connected only" : "disconnected only"}
                                                checked={filterRequest.stravaConnected}
                                                onChange={filterHandleStravaChanged}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="form-group row" key="hasConsent">
                                <label className={`col-sm-2 col-form-label`}>Consent filter</label>
                                <div className="col-sm-1">
                                    <input type="checkbox" className="form-control"
                                        defaultChecked={filterRequest.hasConsent !== undefined}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setFilterRequest({ ...filterRequest, hasConsent: true })
                                            } else {
                                                setFilterRequest({ ...filterRequest, hasConsent: undefined })
                                            }
                                        }}
                                    />
                                </div>
                                {
                                    filterRequest.hasConsent !== undefined
                                        ?
                                        <div className="col-sm-3" style={{ "padding": ".375rem .75rem" }}>
                                            <Form.Check type="switch" id="hasConsentFlag"
                                                label={filterRequest.hasConsent ? "Has consent only" : "Hasn't consent only"}
                                                checked={filterRequest.hasConsent}
                                                onChange={filterHandleConsentChanged}
                                            />
                                        </div>
                                        : null
                                }
                            </div>

                            <div className="form-group row" key="userTypes">
                                <label className={`col-sm-2 col-form-label`}>User Type</label>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="Telegram" custom inline id="cbTelegram"
                                        checked={filterRequest.userTypes?.includes(UserType.Telegram)}
                                        onChange={(e: any) => {
                                            handleUserTypeChanged(e.target.checked, UserType.Telegram);
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="Web" custom inline id="cbWeb"
                                        checked={filterRequest.userTypes?.includes(UserType.Web)}
                                        onChange={(e: any) => {
                                            handleUserTypeChanged(e.target.checked, UserType.Web);
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="Mobile" custom inline id="cbMobile"
                                        checked={filterRequest.userTypes?.includes(UserType.Mobile)}
                                        onChange={(e: any) => {
                                            handleUserTypeChanged(e.target.checked, UserType.Mobile);
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="Unknown" custom inline id="cbUnknown"
                                        checked={filterRequest.userTypes?.includes(UserType.Unknown)}
                                        onChange={(e: any) => {
                                            handleUserTypeChanged(e.target.checked, UserType.Unknown);
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="System" custom inline id="cbSystem"
                                        checked={filterRequest.userTypes?.includes(UserType.System)}
                                        onChange={(e: any) => {
                                            handleUserTypeChanged(e.target.checked, UserType.System);
                                        }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group row" key="operationSystems">
                                <label className={`col-sm-2 col-form-label`}>OS</label>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="iOs" custom inline id="cbIos"
                                        checked={filterRequest.operationSystems?.includes(OperationSystemType.Ios)}
                                        onChange={(e: any) => {
                                            handleOperationSystemChanged(e.target.checked, OperationSystemType.Ios);
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="Android" custom inline id="cbAndroid"
                                        checked={filterRequest.operationSystems?.includes(OperationSystemType.Android)}
                                        onChange={(e: any) => {
                                            handleOperationSystemChanged(e.target.checked, OperationSystemType.Android);
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="Unknown" custom inline id="cbOsUnknown"
                                        checked={filterRequest.operationSystems?.includes(OperationSystemType.Unknown)}
                                        onChange={(e: any) => {
                                            handleOperationSystemChanged(e.target.checked, OperationSystemType.Unknown);
                                        }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group row" key="languages">
                                <label className={`col-sm-2 col-form-label`}>Languages</label>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="En" custom inline id="cbEn"
                                        checked={filterRequest.languages?.includes("en")}
                                        onChange={(e: any) => {
                                            handleLanguageChanged(e.target.checked, "en");
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="Ru" custom inline id="cbRu"
                                        checked={filterRequest.languages?.includes("ru")}
                                        onChange={(e: any) => {
                                            handleLanguageChanged(e.target.checked, "ru");
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="De" custom inline id="cbDe"
                                        checked={filterRequest.languages?.includes("de")}
                                        onChange={(e: any) => {
                                            handleLanguageChanged(e.target.checked, "de");
                                        }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group row" key="subscription">
                                <label className={`col-sm-2 col-form-label`}>Subscription</label>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="Premium" custom inline id="cbPremium"
                                        checked={filterRequest.subscriptions?.includes('premium')}
                                        onChange={(e: any) => {
                                            handleSubscriptionsChanged(e.target.checked, 'premium');
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-sm-2" style={{ "padding": ".375rem .75rem" }}>
                                    <Form.Check type="checkbox" label="Free" custom inline id="cbFree"
                                        checked={filterRequest.subscriptions?.includes('free')}
                                        onChange={(e: any) => {
                                            handleSubscriptionsChanged(e.target.checked, 'free');
                                        }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>);
    }
}

export default UsersView;
