import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps } from 'react-router-dom'
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enGB } from "date-fns/locale";
import { useAuth0 } from "./../../react-auth0-spa";
import { TrainingCore, TrainingInfo, UserShortInfo, UserFullInfo, WeekPlanInfo, TrainingFileInfo } from '../../api/types';
import { PlanType, TrainingCompletionStatus, CoachingType, TrainingDescriptionType } from '../../shared/Primitives';
import { BaseCampClient } from '../../api/BaseCampClient';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { EventApi, View } from '@fullcalendar/core';
import './TrainingsView.scss';
import UserSelector from "./UserSelector";
import ModifyTrainingModal from "./ModifyTrainingModal";
import ModifyWeekPlanModal from "./ModifyWeekPlanModal";
import UserSummary from "./UserSummary";
import TrainingEvent from "./TrainingEvent";
import { round, secondsToTimeString, toUTC } from "../../api/Helpers";

registerLocale('en-GB', enGB)
setDefaultLocale('en-GB');

interface Props extends RouteComponentProps<any> { }

interface CalendarEvent {
    title: string;
    date: Date | undefined;
    start: Date | undefined;
    end: Date | undefined;
    allDay: boolean;
    color: string | undefined;

    training: TrainingInfo | undefined;
    weekPlan: WeekPlanInfo | undefined;
}

interface CalendarState {
    dateFrom: Date;
    dateTo: Date;
}

const TrainingsView = (props: Props) => {
    const { accessToken } = useAuth0();
    const basecamp = new BaseCampClient(accessToken);

    const [users, setUsers] = useState<UserShortInfo[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);
    const [selectedUserProfile, setSelectedUserProfile] = useState<UserFullInfo | undefined>(undefined);
    const [calendarState, setCalendarState] = useState<CalendarState | undefined>(undefined);
    const [trainings, setTrainings] = useState<TrainingInfo[]>([]);
    const [showAddTraining, setShowAddTraining] = useState(false);
    const [showEditTraining, setShowEditTraining] = useState(false);
    const [trainingToModify, setTrainingToModify] = useState<TrainingCore | undefined>(undefined);
    const [trainingToModifyFiles, setTrainingToModifyFiles] = useState<TrainingFileInfo[] | undefined>(undefined);
    const [weekPlans, setWeekPlans] = useState<WeekPlanInfo[]>([]);
    const [weekPlanToModify, setWeekPlanToModify] = useState<WeekPlanInfo | undefined>(undefined);
    const [showWeekPlanModal, setShowWeekPlanModal] = useState(false);

    const trainingEvents: CalendarEvent[] = trainings.map((t: TrainingInfo) => {
        return {
            title: t.description,
            date: t.date,
            training: t,
            weekPlan: undefined,
            allDay: true,
            color: undefined,
            start: undefined,
            end: undefined
        }
    });

    const weekPlanEvents: CalendarEvent[] = weekPlans.map((wp: WeekPlanInfo) => {
        const endAt = new Date(wp.startAt);
        endAt.setDate(endAt.getDate() + 7);
        return {
            title: wp.title,
            start: wp.startAt,
            end: endAt,
            training: undefined,
            weekPlan: wp,
            allDay: true,
            color: '#3788d8',
            date: undefined
        }
    });

    const events: CalendarEvent[] = trainingEvents.concat(weekPlanEvents);

    useEffect(() => {
        basecamp.getUsers().then(res => setUsers(res));

        const userId = props.match.params.userId && parseInt(props.match.params.userId);
        if (userId) {
            onUserChanged(userId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // second argument prevent to run on EVERY render.

    useEffect(() => {
        if (calendarState) {
            basecamp.getTrainings(userId, calendarState.dateFrom, calendarState.dateTo)
            .then(userTrainings => {
                console.log('got trainings', userTrainings);
                setTrainings(userTrainings)
            });

            basecamp.getWeekPlans(userId, calendarState.dateFrom, calendarState.dateTo)
            .then(plans => {
                console.log('got weeks', plans);
                setWeekPlans(plans)
            });
        }
    }, [calendarState]);

    const onUserChanged = (userId: number) => {
        setSelectedUserId(userId);
        setTrainings([]);
        setWeekPlans([]);

        props.history.push(`/trainings/${userId}`);

        setCalendarState(!calendarState ? undefined : {
            dateFrom: calendarState.dateFrom,
            dateTo: calendarState.dateTo
        });
        basecamp.getUserProfile(userId)
            .then(userProfile => {
                console.log('got profile');
                setSelectedUserProfile(userProfile)
            })
            .catch(e => {
                if (e.response.status === 403) {
                    window.location.href = "/users";
                }
            });
    }

    const onNavLinkDayClick = (date: Date) => {
        onModifyTraining({
            id: 0,
            descriptionRu: "",
            descriptionEn: "",
            descriptionAltRu: "",
            descriptionAltEn: "",
            isDescriptionAltEnEdited: false,
            isDescriptionAltRuEdited: false,
            isDescriptionEnEdited: false,
            isDescriptionRuEdited: false,
            title: "",
            date: date,
            dateOriginal: undefined,
            workoutType: "other",
            workoutGenerator: undefined,
            coachingType: CoachingType.RunningFitness,
            activity: undefined,
            links: undefined,
            distancePlanned: undefined,
            distancePlannedMeters: undefined,
            durationPlanned: undefined,
            durationPlannedAlt: undefined,
        });
    }

    const onModifyTraining = (training: TrainingCore | undefined) => {
        if (!training) {
            return;
        }

        setTrainingToModify(training);

        if (training.id === 0) {
            setShowAddTraining(true);
        }
        else {
            setShowEditTraining(true);
            basecamp.getTrainingFiles(training.id)
                .then(files => {
                    console.log('files', files);
                    setTrainingToModifyFiles(files);
                });
        }
    }

    const onNavLinkWeekClick = (weekStartAt: Date, weekPlan: WeekPlanInfo | undefined) => {
        console.log('foundWeekPlan', weekPlan);
        if (weekPlan) {
            setWeekPlanToModify(weekPlan);
        }
        else {
            setWeekPlanToModify({
                id: 0,
                startAt: weekStartAt,
                planType: PlanType.Fitness,
                weekType: "Recovery",
                title: "",
                coachingType: CoachingType.RunningFitness,
                distanceCompleted: 0,
                distancePlanned: 0,
                durationCompleted: 0,
                durationPlanned: 0
            });
        }

        setShowWeekPlanModal(true);
    }

    const eventRender = (arg: {
        isMirror: boolean;
        isStart: boolean;
        isEnd: boolean;
        event: EventApi;
        el: HTMLElement;
        view: View;
    }): void => {
        const training: TrainingInfo = arg.event.extendedProps.training;
        const weekPlan: WeekPlanInfo = arg.event.extendedProps.weekPlan;

        if (training) {
            const distance = training.distanceCompleted;
            const duration = training.durationCompleted;
            const pace = training.paceCompleted && training.workoutType; //training.type;

            arg.el.classList.add('training');
            if (distance && duration && pace && training.description) {
                arg.el.classList.add('training--extended');
            }
            else {
                arg.el.classList.add('training--default');
            }

            if (training.date < new Date()) {
                switch (training.completionStatus) {
                    case TrainingCompletionStatus.None:
                        arg.el.classList.add('bg-danger');
                        break;
                    case TrainingCompletionStatus.Partially:
                        arg.el.classList.add('bg-warning', 'text-dark');
                        break;
                    case TrainingCompletionStatus.Done:
                        arg.el.classList.add('bg-success');
                        break;
                    case TrainingCompletionStatus.Unplanned:
                        arg.el.classList.add('bg-info');
                        break;
                }
            }

            const content = <TrainingEvent training={training} useImperialSystem={selectedUserProfile?.useImperialSystem ?? false}/>;

            ReactDOM.render(content, arg.el);
        }

        if (weekPlan) {
            const content = <div className="d-flex">
                <div className="col col-sm-2">{weekPlan.title}</div>
                <div className="col col-sm-2">Distance: {round(weekPlan.distanceCompleted / 1000, 1)}km / {round(weekPlan.distancePlanned / 1000, 1)}km</div>
                <div className="col col-sm-2">Time: {secondsToTimeString(weekPlan.durationCompleted)} / {secondsToTimeString(weekPlan.durationPlanned)}</div>
            </div>

            ReactDOM.render(content, arg.el);
        }
    }

    const onEventClick = (e: {
        el: HTMLElement;
        event: EventApi;
        jsEvent: MouseEvent;
        view: View;
    }): void => {
        const props = e.event.extendedProps;
        if (props.training) {
            onModifyTraining(props.training);
        }
        else if (props.weekPlan) {
            onNavLinkWeekClick(props.weekPlan.startAt, props.weekPlan);
        }
    }

    const onDeleteTraining = (trainingId: number) => {
        basecamp
            .deleteTraining(trainingId)
            .then(() => {
                const trainingsUpdated = trainings.filter((t) => t.id !== trainingId);
                setShowEditTraining(false);
                setShowAddTraining(false);
                setTrainingToModify(undefined);
                setTrainings(trainingsUpdated);
            });
    }

    const handleCloseOnModify = () => {
        setShowAddTraining(false);
        setShowEditTraining(false);
        setTrainingToModify(undefined);

        setShowWeekPlanModal(false);
        setWeekPlanToModify(undefined);
    }

    const handleSaveOnAdd = (training: TrainingCore, files?: File[]) => {
        if (!selectedUserId) {
            throw new Error("selectedUserId is undefined");
        }
        basecamp
            .addTraining(selectedUserId, training)
            .then((id) => {
                training.id = id;
                if (files && files.length > 0) {
                    basecamp.uploadTrainingFiles(id, files);
                }

                const newTraining = training as TrainingInfo;
                newTraining.description = selectedUserProfile?.language === "ru" ? training.descriptionRu : training.descriptionEn;
                const trainingsUpdated = trainings.concat([newTraining]);

                setShowAddTraining(false);
                setTrainingToModify(undefined);
                setTrainings(trainingsUpdated);
            });
    }

    const handleSaveOnEdit = (training: TrainingCore, files?: File[]) => {
        basecamp
            .updateTraining(training)
            .then((response) => {
                if (files && files.length > 0) {
                    basecamp.uploadTrainingFiles(training.id, files);
                }
                const trainingToUpdate = trainings.filter((t) => t.id === training.id)[0];
                trainingToUpdate.date = training.date;
                //trainingToUpdate.type = training.type;
                trainingToUpdate.descriptionRu = training.descriptionRu;
                trainingToUpdate.descriptionEn = training.descriptionEn;
                trainingToUpdate.descriptionAltRu = training.descriptionAltRu;
                trainingToUpdate.descriptionAltEn = training.descriptionAltEn;
                trainingToUpdate.description = selectedUserProfile?.language === "ru" ? training.descriptionRu : training.descriptionEn;
                //trainingToUpdate.customType = training.customType;
                trainingToUpdate.workoutType = training.workoutType;
                trainingToUpdate.coachingType = training.coachingType;
                trainingToUpdate.links = training.links;
                trainingToUpdate.dateOriginal = response.dateOriginal;
                trainingToUpdate.isDescriptionAltEnEdited = training.isDescriptionAltEnEdited;
                trainingToUpdate.isDescriptionAltRuEdited = training.isDescriptionAltRuEdited;
                trainingToUpdate.isDescriptionEnEdited = training.isDescriptionEnEdited;
                trainingToUpdate.isDescriptionRuEdited = training.isDescriptionRuEdited;
                trainingToUpdate.distancePlanned = training.distancePlanned;
                trainingToUpdate.distancePlannedMeters = training.distancePlannedMeters;
                trainingToUpdate.durationPlanned = training.durationPlanned;
                trainingToUpdate.durationPlannedAlt = training.durationPlannedAlt;

                let trainingsFiltered = trainings.filter((t) => t.id !== training.id);
                trainingsFiltered = trainingsFiltered.concat([trainingToUpdate]);

                setShowEditTraining(false);
                setTrainingToModify(undefined);
                setTrainings(trainingsFiltered);
            });
    }

    const handleResetDescription = (trainingId: number, descriptionType: TrainingDescriptionType, description: string | undefined) => {
        const trainingToUpdate = trainings.filter((t) => t.id === trainingId)[0];
        switch (descriptionType) {
            case TrainingDescriptionType.En:
                trainingToUpdate.descriptionEn = description || "";
                trainingToUpdate.isDescriptionEnEdited = false;
                break;
            case TrainingDescriptionType.Ru:
                trainingToUpdate.descriptionRu = description || "";
                trainingToUpdate.isDescriptionRuEdited = false;
                break;
            case TrainingDescriptionType.AltEn:
                trainingToUpdate.descriptionAltEn = description || "";
                trainingToUpdate.isDescriptionAltEnEdited = false;
                break;
            case TrainingDescriptionType.AltRu:
                trainingToUpdate.descriptionAltRu = description || "";
                trainingToUpdate.isDescriptionAltRuEdited = false;
                break;
        }

        let trainingsFiltered = trainings.filter((t) => t.id !== trainingId);
        trainingsFiltered = trainingsFiltered.concat([trainingToUpdate]);

        setTrainings(trainingsFiltered);
    }

    const handleFileDelete = (trainingFileId: number) => {
        basecamp.deleteTrainingFile(trainingFileId)
            .then((files) => {
                setTrainingToModifyFiles(files);
            })
    }

    const handleWeekPlanSave = (weekPlan: WeekPlanInfo) => {
        if (!selectedUserId) {
            throw new Error("selectedUserId is undefined");
        }
        if (weekPlan.id > 0) {
            basecamp
                .updateWeekPlan(selectedUserId, weekPlan)
                .then((weekPlanUpdated) => {
                    if (weekPlanUpdated) {
                        const weekPlansFiltered = weekPlans.filter((w) => w.id !== weekPlan.id);
                        const newWeeks = weekPlansFiltered.concat([weekPlanUpdated]);
                        setWeekPlans(newWeeks);
                    }
                })
                .catch(error => {
                    alert(error.response.data);
                });
        }
        else {
            basecamp
                .addWeekPlan(selectedUserId, weekPlan)
                .then((weekPlanCreated) => {
                    if (weekPlanCreated) {
                        const newWeeks = weekPlans.concat([weekPlanCreated]);
                        setWeekPlans(newWeeks);
                    }
                })
                .catch(error => {
                    alert(error.response.data);
                });
        }

        setShowWeekPlanModal(false);
        setWeekPlanToModify(undefined);
    }

    const handleWeekPlanDelete = (weekPlanId: number) => {
        basecamp
            .deleteWeekPlan(weekPlanId)
            .then(() => {
                let weekPlansFiltered = weekPlans.filter((w) => w.id !== weekPlanId);
                setWeekPlans(weekPlansFiltered);

                setShowWeekPlanModal(false);
                setWeekPlanToModify(undefined);
            });
    }

    const handleWeekPlanGenerate = (weekPlanId: number) => {
        if (!selectedUserId) {
            throw new Error("selectedUserId is undefined");
        }
        basecamp
            .generateWeekPlan(weekPlanId)
            .then((ok) => {
                if (ok) {
                    setCalendarState(!calendarState ? undefined : {
                        dateFrom: calendarState.dateFrom,
                        dateTo: calendarState.dateTo
                    });
                }
                setShowWeekPlanModal(false);
                setWeekPlanToModify(undefined);
            });
    }
    const userId = parseInt(props.match.params.userId);
    return <div className='pb-3'>
        <div style={{ marginTop: '10px', marginBottom: '20px' }}>
            <UserSelector selectedUserId={userId} users={users} onUserChanged={onUserChanged} />
        </div>

        {selectedUserProfile ? <div className="mb-2"><UserSummary user={selectedUserProfile} /></div> : null}

        {selectedUserId
            ? <FullCalendar
                defaultView='dayGridMonth'
                plugins={[dayGridPlugin]}
                weekends={true}
                weekNumbers={true}
                events={events}
                aspectRatio={1.5}
                firstDay={1}
                timeZone='UTC'
                //dayRender={dayRender}
                eventRender={eventRender}
                eventClick={onEventClick}
                navLinks={true}
                navLinkDayClick={(date) => onNavLinkDayClick(toUTC(date))}
                navLinkWeekClick={(weekStart) => onNavLinkWeekClick(toUTC(weekStart), undefined)}
                datesRender={(e) => setCalendarState({
                    dateFrom: e.view.activeStart,
                    dateTo: e.view.activeEnd
                })}
            /> : null}

        {showAddTraining
            ? <ModifyTrainingModal
                userId={userId}
                training={trainingToModify as TrainingInfo}
                basecamp={basecamp}
                onDelete={onDeleteTraining}
                onClose={handleCloseOnModify}
                onSave={handleSaveOnAdd}
                onFileDelete={handleFileDelete}
                files={undefined}
                onResetDescription={handleResetDescription}
            />
            : null
        }

        {showEditTraining
            ? <ModifyTrainingModal
                userId={userId}
                training={trainingToModify as TrainingInfo}
                basecamp={basecamp}
                onDelete={onDeleteTraining}
                onClose={handleCloseOnModify}
                onSave={handleSaveOnEdit}
                onFileDelete={handleFileDelete}
                files={trainingToModifyFiles as TrainingFileInfo[]}
                onResetDescription={handleResetDescription}
            />
            : null
        }

        {showWeekPlanModal
            ? <ModifyWeekPlanModal
                weekPlan={weekPlanToModify as WeekPlanInfo}
                onDelete={handleWeekPlanDelete}
                onClose={handleCloseOnModify}
                onSave={handleWeekPlanSave}
                onGenerate={handleWeekPlanGenerate}
            />
            : null
        }
    </div>
}

export default TrainingsView;
