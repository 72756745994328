import { CoachingType } from '../shared/Primitives';

export const weekTypeValuesByCoachingType = [
    {
        coachingType: CoachingType.RunningFitness,
        weekTypes: [
            {
                value: 'Base',
                label: 'Base'
            }, 
            { 
                value: 'Recovery',
                label: 'Recovery'
            },
            {
                value: 'Fun',
                label: 'Fun'
            },
        ]
    },
    {
        coachingType: CoachingType.RunningRace,
        weekTypes: [
            {
                value: 'Base',
                label: 'Base'
            },
            {
                value: 'Fun',
                label: 'Fun'
            },
            {
                value: 'Recovery',
                label: 'Recovery'
            },
            {
                value: 'Taper',
                label: 'Taper'
            },
            {
                value: 'Threshold',
                label: 'Threshold'
            },
            {
                value: 'Speed',
                label: 'Speed'
            },
            {
                value: 'Vo2Max',
                label: 'Vo2Max'
            },
            {
                value: 'Active',
                label: 'Active'
            }
        ]
    },
    {
        coachingType: CoachingType.CyclingFitness,
        weekTypes:
        [
            {
                value: 'Fun',
                label: 'Fun'
            },
            {
                value: 'Recovery',
                label: 'Recovery'
            }
        ]
    },
    {
        coachingType: CoachingType.CyclingRace,
        weekTypes: [
            {
                value: 'Preparation',
                label: 'Preparation'
            },
            {
                value: 'Strength1',
                label: 'Strength1'
            },
            {
                value: 'Strength2',
                label: 'Strength2'
            },
            {
                value: 'Aerobic1',
                label: 'Aerobic1'
            },
            {
                value: 'Aerobic2',
                label: 'Aerobic2'
            },
            {
                value: 'Fitness',
                label: 'Fitness'
            },
            {
                value: 'Tempo',
                label: 'Tempo'
            },
            {
                value: 'Taper',
                label: 'Taper'
            },
            {
                value: 'Fun',
                label: 'Fun'
            },
            {
                value: 'Recovery',
                label: 'Recovery'
            }
        ]
    },
    {
        coachingType: CoachingType.TriathlonFitness,
        weekTypes: [
            {
                value: 'FunRun',
                label: 'FunRun'
            },
            {
                value: 'FunBike',
                label: 'FunBike'
            },
            {
                value: 'Recovery',
                label: 'Recovery'
            }
        ]
    },
    {
        coachingType: CoachingType.TriathlonRace,
        weekTypes: [
            {
                value: 'FunRun',
                label: 'FunRun'
            },
            {
                value: 'FunBike',
                label: 'FunBike'
            },
            {
                value: 'Recovery',
                label: 'Recovery'
            },
            {
                value: 'PreparationRun',
                label: 'PreparationRun'
            },
            {
                value: 'PreparationBike',
                label: 'PreparationBike'
            },
            {
                value: 'Strength1Run',
                label: 'Strength1Run'
            },
            {
                value: 'Strength1Bike',
                label: 'Strength1Bike'
            },
            {
                value: 'Strength2Run',
                label: 'Strength2Run'
            },
            {
                value: 'Strength2Bike',
                label: 'Strength2Bike'
            },
            {
                value: 'Aerobic1Run',
                label: 'Aerobic1Run'
            },
            {
                value: 'Aerobic1Bike',
                label: 'Aerobic1Bike'
            },
            {
                value: 'Aerobic2Run',
                label: 'Aerobic2Run'
            },
            {
                value: 'Aerobic2Bike',
                label: 'Aerobic2Bike'
            },
            {
                value: 'FitnessRun',
                label: 'FitnessRun'
            },
            {
                value: 'FitnessBike',
                label: 'FitnessBike'
            },
            {
                value: 'TempoRun',
                label: 'TempoRun'
            },
            {
                value: 'TempoBike',
                label: 'TempoBike'
            },
            {
                value: 'Taper',
                label: 'Taper'
            }
        ]
    }
];